import { useEffect } from "react"
import { useProfileStore } from "@/web/stores/profile"
import { readContract } from "wagmi/actions"
import RareToken from "@/abis/RareToken.json"
import { formatCurrency, getUSDCCurrency } from "@/utils/currency"
import { useConfig } from "wagmi"

export const useUsdcPriceUpdate = (
  walletAddress: string
): { usdcBalance: number; formatBalance: number } => {
  const { usdcBalance, setUsdcBalance } = useProfileStore()
  const config = useConfig()

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balance = await readContract(config, {
          address: getUSDCCurrency().address,
          abi: RareToken,
          functionName: "balanceOf",
          args: [walletAddress],
        })
        if (balance !== undefined) {
          setUsdcBalance(Number(balance))
        }
      } catch (error) {}
    }
    fetchBalance()
  }, [walletAddress, usdcBalance])

  return {
    usdcBalance,
    formatBalance: Number(
      formatCurrency(BigInt(usdcBalance), getUSDCCurrency())
    ),
  }
}
