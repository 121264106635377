import { useProfileStore } from "@/web/stores/profile"
import { cn } from "@/utils/cn"

export const UserNotifications: React.FC<{
  count: number
  unread: number
  blink?: boolean
}> = ({ unread = 0, blink = false }) => {
  const { profile } = useProfileStore()

  // if more than 99 show 99+
  const counter = unread > 99 ? "99+" : unread

  return (
    <div className="relative">
      <div
        className={cn(
          "absolute right-0 h-2 w-2 rounded-full bg-red-500",
          blink && "animate-pulse",
          unread === 0 && "hidden"
        )}
      ></div>
      <a
        href={`https://superrare.com/notifications/${profile?.username}`}
        target="_blank"
        className={`
          flex h-6 w-6 items-center justify-center rounded-full border border-black text-xs
          dark:border-white
          lg:h-7 lg:w-7
        `}
      >
        {counter}
      </a>
    </div>
  )
}
