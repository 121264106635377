"use client"

import { currentNetwork } from "@/constants/network"
import { useProfileStore } from "@/web/stores/profile"
import { trpc } from "@/web/clients/trpc"
import { Avatar } from "@/ui/avatar"
import { Button } from "@/ui/button"
import { Dialog } from "@/ui/dialog"
import { Loader } from "@/ui/loader"
import { Popover } from "@/ui/popoverresponsive"
import { cn } from "@/utils/cn"
import { parseViemError } from "@/utils/errors"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { AnimatePresence, motion } from "framer-motion"
import { MouseEventHandler, useEffect, useMemo } from "react"
import toast from "react-hot-toast"
import { useToggle } from "react-use"
import { useAccount, useSwitchChain } from "wagmi"
import { useRarePriceUpdater } from "@/web/hooks/useRarePriceUpdater"
import { useIsWhitelisted } from "@/web/hooks/useIsWhitelisted"
import makeBlockie from "ethereum-blockies-base64"
import { useSRDisconnect } from "@/web/hooks/useSRDisconnect"
import { CompleteProfileCta } from "@/components/header/CompleteProfileCta"
import { AvatarWithDisplayName } from "@/components/header/AvatarWithDisplayName"
import { SubNavUserHeader } from "@/components/header/SubNavUserHeader"
import { Address } from "viem"
import HR from "@/ui/hr"
import Link from "next/link"
import { UserNotifications } from "@/components/header/UserNotifications"
import { useUsdcPriceUpdate } from "@/web/hooks/useUsdcPriceUpdate"

export type AccountProps = {
  address: string
  balanceDecimals?: number
  balanceFormatted?: string
  balanceSymbol?: string
  displayBalance?: string
  displayName: string
  ensAvatar?: string
  ensName?: string
  hasPendingTransactions: boolean
}

export const CurrencyBalanceFormat = ({
  balance,
  isFormatted = false,
  decimals = 2,
  symbol = "ETH",
  className,
  symbolClass = "text-xs",
  ...props
}: {
  balance: number | string | undefined
  isFormatted?: boolean
  decimals?: number
  symbol?: string
  className?: string
  symbolClass?: string
}): JSX.Element => {
  const formatBalance = (balance: number): string => {
    if (balance >= 1_000_000)
      return (balance / 1_000_000).toFixed(decimals) + "m"
    if (balance >= 1_000) return (balance / 1_000).toFixed(decimals) + "k"
    return balance.toFixed(decimals)
  }

  const b = Number(balance)

  return (
    <span
      className={cn("text-base text-typography-grey-1", className)}
      {...props}
    >
      {isFormatted ? b.toFixed(decimals) : formatBalance(b)}
      <span className={cn("ml-1 text-xs", symbolClass)}>{symbol}</span>
    </span>
  )
}

export const UserDropdownLink = ({
  href,
  children,
  onClick,
}: any): JSX.Element => {
  if (href) {
    return (
      <Link
        href={href}
        onClick={onClick}
        className={`
          inline-block w-full cursor-pointer rounded-md p-2
          hover:bg-sr-bg-tertiary hover:dark:bg-night-grey-4
        `}
      >
        {children}
      </Link>
    )
  }

  return (
    <a
      className={`
        inline-block w-full cursor-pointer rounded-md p-2
        hover:bg-sr-bg-tertiary hover:dark:bg-night-grey-4
      `}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export const UserDropdown = ({
  account,
}: {
  account: AccountProps
}): JSX.Element => {
  const { disconnect } = useSRDisconnect()
  const { profile } = useProfileStore()
  const { isWhitelisted } = useIsWhitelisted(
    profile?.ethereum_address as Address | undefined
  )
  const [popoverIsOpen, setPopoverIsOpen] = useToggle(false)

  return (
    <Popover
      mobile="full"
      align="end"
      sideOffset={28}
      alignOffset={0}
      className="z-50 w-[288px] p-0"
      open={popoverIsOpen}
      onOpenChange={setPopoverIsOpen}
    >
      <Popover.Trigger>
        <Avatar
          className="size-7"
          src={
            profile?.avatar ||
            account?.ensAvatar ||
            makeBlockie(account?.address)
          }
          alt={account?.displayName}
        />
      </Popover.Trigger>
      <Popover.Content>
        <SubNavUserHeader address={account.address as Address} />

        <nav className="flex flex-col items-start px-6 py-4">
          <div className="mb-4">
            <AvatarWithDisplayName
              avatarSrc={
                profile?.avatar ||
                account?.ensAvatar ||
                makeBlockie(account?.address || "0x")
              }
              avatarAlt={account?.address}
              profile={profile}
            />
          </div>

          {profile && profile.username && (
            <>
              {isWhitelisted && (
                <>
                  <UserDropdownLink href={`/create`}>Create</UserDropdownLink>
                </>
              )}

              <UserDropdownLink
                className="text-base capitalize"
                href={`/account/dashboard`}
              >
                Dashboard
              </UserDropdownLink>

              <UserDropdownLink href={`/account/settings`}>
                Settings
              </UserDropdownLink>
            </>
          )}

          {!profile?.username && (
            <CompleteProfileCta
              className="mb-4"
              onClick={() => setPopoverIsOpen()}
            />
          )}
          <HR className="my-2" />

          <UserDropdownLink onClick={disconnect}>Disconnect</UserDropdownLink>
        </nav>
      </Popover.Content>
    </Popover>
  )
}

export const UserNotificationsItem = ({
  user,
  onClick,
}: {
  user: any
  onClick: any
}): JSX.Element => {
  return (
    <button
      className={`
        flex cursor-pointer items-center gap-x-3 rounded-md p-2
        dark:hover:bg-night-grey-3
        hover:bg-day-grey-5
      `}
      onClick={onClick}
    >
      <Avatar
        className="h-8 w-8"
        src={user.avatar || makeBlockie(user?.ethereum_address || "0x")}
        alt={user.name}
      />
      <div className="flex gap-x-1 text-sm text-typography-grey-3">
        <span
          className={`
            text-black
            dark:text-white
          `}
        >
          {user.name}
        </span>{" "}
        {user.action}
      </div>
    </button>
  )
}

export const ConnectWalletButton = ({
  onClick,
  wrapperClassName,
  btnClassName,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>
  wrapperClassName?: string
  btnClassName?: string
}): JSX.Element => {
  const { profile, setProfile } = useProfileStore()
  const { address, chain } = useAccount()
  const { error, isSuccess, switchChain } = useSwitchChain()
  const [wrongChain, toggleWrongChain] = useToggle(false)

  useEffect(() => {
    if (address) toggleWrongChain(chain?.id !== currentNetwork.id)
    if (isSuccess) toast.success("Switched!")
    if (error) toast.error(parseViemError(error.message))
  }, [address, chain, error])

  const { formatBalance: rareBalance } = useRarePriceUpdater(address)
  const { formatBalance: usdcBalance } = useUsdcPriceUpdate(address || "")

  useMemo(() => {
    setProfile({ ...profile, rareBalance, usdcBalance })
  }, [rareBalance])

  const getProfile = trpc.profile.getByAddresses.useQuery(
    {
      addresses: address ? [address.toLowerCase()] : [],
    },
    {
      enabled: false,
    }
  )

  useEffect(() => {
    if (address) getProfile.refetch()
  }, [address])

  useEffect(() => {
    const user = getProfile.data?.users.users_by_ethereum_addresses[0]
    setProfile(user || {})
  }, [getProfile.data])

  const handleKeyDown = (event: any): void => {
    if (event.key === "Enter" || event.key === "Space") {
      onClick?.(event)
    }
  }

  return (
    <>
      <Dialog open={wrongChain}>
        <Dialog.Content title="Wrong Network" disableClose>
          <div className="">
            {chain && (
              <div className="mt-2 pb-6">
                Switch to {currentNetwork.name} to connect to SuperRare.
              </div>
            )}

            <Button
              uppercase
              className="w-full"
              disabled={!switchChain || currentNetwork.id === chain?.id}
              key={currentNetwork.id}
              onClick={() => switchChain?.({ chainId: currentNetwork.id })}
            >
              Switch to {currentNetwork.name}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog>
      <div
        className={cn(wrapperClassName)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        aria-pressed="false"
      >
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            const ready = mounted && authenticationStatus !== "loading"
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === "authenticated")

            return (
              <div
                {...(!ready && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <div
                        className={`
                          px-4 pt-3
                          lg:px-0 lg:pt-0
                        `}
                      >
                        <Button
                          className={cn(btnClassName)}
                          onClick={openConnectModal}
                          type="button"
                          uppercase
                          kind="primary"
                          size="lg"
                        >
                          Connect
                        </Button>
                      </div>
                    )
                  }
                  if (chain.unsupported) {
                    return (
                      <button onClick={openChainModal} type="button">
                        Wrong network
                      </button>
                    )
                  }
                  return (
                    <>
                      <div
                        className={`
                          hidden items-center gap-x-3
                          lg:flex
                        `}
                      >
                        <UserNotifications
                          count={profile?.unread_notifications_count || 0}
                          unread={profile?.unread_notifications_count || 0}
                        />
                        <div className="size-7">
                          <AnimatePresence mode="wait">
                            {getProfile.isLoading ? (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0, y: 15 }}
                                key="loader"
                              >
                                <Loader kind="simple" />
                              </motion.div>
                            ) : (
                              <motion.div
                                initial={{ opacity: 0, y: -15 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0 }}
                                key="dropdown"
                              >
                                <UserDropdown account={account} />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      </div>
                    </>
                  )
                })()}
              </div>
            )
          }}
        </ConnectButton.Custom>
      </div>
    </>
  )
}
