import React, { ReactNode, useState } from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import { cn } from "@/utils/cn"

type PopoverContentProps = PopoverPrimitive.PopoverContentProps & {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children?: ReactNode
}

const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        sideOffset={8}
        alignOffset={-10}
        style={{ zIndex: 50, position: "relative" }}
        {...props}
        className={cn(
          "overflow-hidden rounded-md border border-sr-border-primary bg-sr-bg-primary p-4",
          props.className
        )}
        ref={forwardedRef}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  )
)

const PopoverTrigger = PopoverPrimitive.Trigger

const Popover = ({
  open: controlledOpen,
  onOpenChange: controlledOnOpenChange,
  children,
}: PopoverContentProps): JSX.Element => {
  const [open, setOpen] = useState(controlledOpen ?? false)

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    if (controlledOnOpenChange) {
      controlledOnOpenChange(isOpen)
    }
  }

  const handleClickInside = () => {
    setOpen(false)
    if (controlledOnOpenChange) {
      controlledOnOpenChange(false)
    }
  }
  return (
    <PopoverPrimitive.Root open={open} onOpenChange={handleOpenChange}>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement, {
              onClick: handleClickInside,
            })
          : child
      )}
    </PopoverPrimitive.Root>
  )
}

Popover.Trigger = PopoverTrigger
Popover.Content = PopoverContent

export { Popover }
