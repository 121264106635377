import { useEffect } from "react"
import { useProfileStore } from "@/web/stores/profile"
import { getRareCurrency } from "@/utils/currency"
import { RareTokenAbi } from "@/contracts/rare-token"
import { Address } from "@/utils/common.types"
import { getChainClients } from "@/utils/chainClients"
import { CHAIN_ID } from "@/constants/environment"
import { formatEther } from "viem"

export const useRarePriceUpdater = (
  walletAddress?: Address
): { rareBalance?: bigint; formatBalance?: number } => {
  const { setRareBalance, rareBalance } = useProfileStore()
  const publicClient = getChainClients(CHAIN_ID).viem

  useEffect(() => {
    if (!walletAddress) {
      return
    }
    const fetchBalance = async () => {
      try {
        const balance = await publicClient.readContract({
          address: getRareCurrency().address,
          abi: RareTokenAbi,
          functionName: "balanceOf",
          args: [walletAddress],
        })
        if (balance !== undefined) {
          setRareBalance(balance)
        }
      } catch (error) {}
    }
    fetchBalance()
  }, [walletAddress, setRareBalance])

  return {
    rareBalance,
    formatBalance: rareBalance
      ? parseFloat(formatEther(rareBalance))
      : undefined,
  }
}
