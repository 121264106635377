"use client"
import { useProfileStore } from "@/web/stores/profile"
import { EthformatAddress } from "@/ui/ethaddress"
import { useBalance } from "wagmi"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"
import { Address, formatEther } from "viem"

export const SubNavUserHeader = ({
  address,
}: {
  address: Address
}): JSX.Element => {
  const { data: balanceEth } = useBalance({
    address,
  })
  const { rareBalance } = useProfileStore()

  const ethBalance = balanceEth?.formatted
  const rareBalanceFormatted = parseFloat(formatEther(rareBalance))

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(address)
  }

  return (
    <div
      className={`
        border-b border-sr-border-tertiary bg-day-grey-5 px-4 py-4
        dark:bg-night-grey-5
        lg:px-6
      `}
    >
      <p className="text-sm text-typography-grey-2">
        <span
          role="button"
          tabIndex={0}
          onClick={copyAddressToClipboard}
          onKeyUp={copyAddressToClipboard}
          className="hover:cursor-pointer"
        >
          {EthformatAddress(address)}
        </span>
      </p>
      <div className="mt-1 flex items-center gap-x-2 text-xs text-day-grey-3">
        <CurrencyBalanceFormat
          balance={ethBalance || 0}
          decimals={3}
          isFormatted
          symbol="ETH"
        />
        <span className="h-4 w-1 border-r border-sr-border-quaternary"></span>
        <CurrencyBalanceFormat
          balance={rareBalanceFormatted || 0}
          decimals={1}
          symbol="RARE"
        />
      </div>
    </div>
  )
}
